* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #e6eee6bf;
  overflow-x: hidden;
}

.announcement-banner {
  color: #fff;
  text-align: center;
  background-color: red;
  padding: 20px;
  font-size: 24px;
  font-weight: bold;
}

.header {
  z-index: 1000;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: fixed;
  top: 0;
}

.header img {
  width: 130px;
  height: auto;
}

.navbar {
  -webkit-user-select: none;
  user-select: none;
  flex-wrap: wrap;
  justify-content: center;
  font-family: Open Sans, sans-serif;
  display: none;
}

.navbar a {
  color: #787c68;
  padding: 25px 16px;
  font-size: 17px;
  font-weight: 1000;
  text-decoration: none;
  transition: color .3s;
  display: inline-block;
}

.navbar a:hover, .navbar a.active {
  color: #7c9924;
}

.buy-tickets-button {
  color: #fff;
  background-color: #8b9f4f;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 6px;
  margin-left: 10px;
  padding: 18px 17px;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  font-weight: 800;
  text-decoration: none;
  display: flex;
}

.buy-tickets-button:hover {
  color: #fff;
  background-color: #7b8f41;
}

.hamburger {
  cursor: pointer;
  flex-direction: column;
  padding: 10px;
  display: flex;
}

.hamburger span {
  background: #8b9f4f;
  width: 25px;
  height: 3px;
  margin: 4px 0;
  transition: all .4s;
}

@media (width >= 768px) {
  .navbar {
    display: flex;
  }

  .hamburger {
    display: none;
  }
}

@media (width <= 768px) {
  .navbar {
    background-color: #fff;
    flex-direction: column;
    align-items: center;
    width: 100%;
    display: none;
    position: absolute;
    top: 60px;
    left: 0;
    box-shadow: 0 2px 4px #0000001a;
  }

  .navbar a {
    text-align: center;
    width: 100%;
    padding: 15px 10px;
    font-size: 14px;
  }

  .navbar .buy-tickets-button {
    width: 80%;
    margin-top: 10px;
  }

  .hamburger, .navbar.active {
    display: flex;
  }
}

@media (width <= 480px) {
  .header {
    padding: 0 10px;
  }

  .header img {
    margin-bottom: 10px;
  }

  .navbar a {
    padding: 10px;
    font-size: 12px;
  }

  .navbar .buy-tickets-button {
    padding: 10px;
    font-size: 14px;
  }
}

@keyframes myanim {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animateme {
  animation: 3s myanim;
}

#backToTopBtn {
  z-index: 99;
  cursor: pointer;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

#backToTopBtn:hover {
  opacity: .8;
}

#home {
  text-align: left;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 80px;
  padding-top: 130px;
  padding-left: 10%;
  display: flex;
  position: relative;
}

#background-video, #fallback-image {
  object-fit: cover;
  z-index: -1;
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.horizontal-space {
  margin-left: 20px;
}

#home h3 {
  color: #b5d07b;
  margin: 0;
  font-size: 2.125em;
}

#home h1 {
  color: #fff;
  margin: 0;
  font-size: 4.375em;
}

#home p {
  color: #fff;
  margin-top: 10px;
  font-size: 1em;
}

#home button {
  color: #7b8f41;
  cursor: pointer;
  background-color: #fff;
  border: 2px solid #7b8f41;
  margin-top: 10px;
  padding: 7px 16px;
  font-size: 16px;
  font-weight: bold;
  transition: background-color .3s, color .3s;
}

#home button:hover {
  color: #fff;
  background-color: #7b8f41;
}

#countdown {
  color: #fff;
  background-color: #7b8f41;
  border-radius: 5px;
  margin-top: 10px;
  margin-left: 20px;
  padding: 5px;
  font-size: 20px;
  display: inline-block;
  box-shadow: 0 4px 8px #0000001a;
}

#sponsors, #media, #speakers, #exhibition, #agenda, #about, #location, #resource, #event_partners {
  padding: 40px 0;
  position: relative;
  overflow: hidden;
}

#sponsors .content, #media .content, #speakers .content, #exhibition .content, #agenda .content, #about .content, #location .content, #resource .content, #event_partners .content {
  z-index: 1;
  margin-left: 10rem;
  padding: 20px;
  position: relative;
}

#sponsors h1, #media h1, #speakers h1, #exhibition h1, #agenda h1, #about h1, #location h1, #resource h1, #event_partners h1 {
  font-size: 2.5em;
}

.heading-with-icon {
  margin: 0;
  font-size: 3em;
  font-weight: bold;
  display: inline-block;
  position: relative;
}

.heading-with-icon:before {
  content: url("solar1.3aa2c0e9.png");
  z-index: -1;
  width: 1px;
  height: -1px;
  position: absolute;
  top: 50%;
  left: -110px;
  transform: translateY(-52%)scale(.29);
}

@media (width <= 900px) {
  #sponsors .content, #media .content, #speakers .content, #exhibition .content, #agenda .content, #about .content, #location .content, #resource .content, #event_partners .content {
    margin-left: 0;
  }

  .heading-button-container {
    margin-left: 3rem;
  }

  .heading-with-icon:before {
    left: -120px;
  }
}

.sponsor-container {
  grid-template-columns: auto 1fr;
  align-items: start;
  gap: 10px;
  display: grid;
}

.sponsor-item {
  display: contents;
}

.sponsor-box {
  width: 100%;
  height: auto;
  display: block;
}

.sponsor-box p {
  text-align: center;
  margin: 0;
}

.sponsor-text {
  color: #fff;
  font-size: 27px;
  font-weight: 1000;
}

.special-sponsor {
  -webkit-user-select: none;
  user-select: none;
  grid-column: 1;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 60px;
  margin-top: 1rem;
  display: flex;
}

.special-sponsor-text {
  margin-left: auto;
  font-family: Pacifico, cursive;
  font-size: 24px;
  font-weight: 1000;
}

.logo-container, .logo-container1, .logo-container2, .logo-container3, .logo-container4 {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding: 10px;
  display: flex;
}

.sponsor-logo {
  object-fit: contain;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

@media (width <= 1300px) {
  .logo-container, .logo-container1, .logo-container2, .logo-container3, .logo-container4 {
    gap: 20px;
    padding: 0;
  }
}

@media (width <= 768px) {
  .logo-container, .logo-container1, .logo-container2, .logo-container3, .logo-container4 {
    gap: 10px;
    padding: 0;
  }
}

@media (width <= 480px) {
  .logo-container, .logo-container1, .logo-container2, .logo-container3, .logo-container4 {
    gap: 5px;
    padding: 0;
  }
}

#sponsors {
  align-items: flex-start;
  margin-top: 20px;
  display: flex;
}

#sponsors .heading-with-icon {
  flex-grow: 1;
}

#sponsors .heading-button-container {
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  display: flex;
}

.heading-button-container a {
  text-decoration: none;
}

#sponsors .sponsor-button {
  color: #7b8f41;
  cursor: pointer;
  background-color: #fff;
  border: 2px solid #7b8f41;
  justify-content: center;
  align-items: center;
  margin-right: 7rem;
  padding: 10px 20px;
  font-family: Open Sans, sans-serif;
  font-size: 20px;
  font-weight: bold;
  transition: background-color .3s, color .3s, padding-right .3s;
  display: flex;
  position: relative;
}

#sponsors .sponsor-button:hover {
  color: #fff;
  background-color: #7b8f41;
  padding-right: 40px;
}

#sponsors .sponsor-button:hover:after {
  content: "→";
  color: #fff;
  font-size: 23px;
  transition: right .3s;
  position: absolute;
  right: 10px;
}

.sponsor-link {
  background-color: #0000;
  border-radius: 2rem;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 60px;
  padding: 10px;
  text-decoration: none;
  transition: background-color .3s;
  display: flex;
}

.sponsor-link:hover, .sponsor-link:active {
  background-color: #55c955;
}

.sponsor-link:active {
  transition: none;
}

@media (width <= 1000px) {
  #sponsors .sponsor-button {
    white-space: normal;
    padding: 5px 10px;
    font-size: 10px;
    right: 3rem;
  }

  #sponsors .sponsor-button:hover:after {
    content: "→";
  }
}

.sponsor-images {
  justify-content: space-between;
  display: flex;
}

.left-image, .right-images {
  flex: 1;
}

.left-image {
  margin-top: 200px;
}

.right-images {
  flex-direction: column;
  display: flex;
}

.small-image {
  width: auto;
  max-width: 450px;
  height: auto;
  max-height: 450px;
}

@media (width <= 768px) {
  .sponsor-box {
    width: 100px;
    height: 34px;
    font-size: 18px;
  }

  .sponsor-images {
    flex-wrap: wrap;
    margin-top: 2rem;
  }

  #sponsors .sponsor-button {
    margin-right: 30px;
  }

  .logo-container, .logo-container1, .logo-container2, .logo-container3, .logo-container4 {
    flex-wrap: wrap;
    width: 100%;
    display: flex;
  }

  .special-sponsor {
    -webkit-user-select: none;
    user-select: none;
    justify-content: center;
    width: 130px;
    height: 50px;
  }

  .sponsor-link {
    width: 105px;
    height: 40px;
  }
}

#media .first {
  color: #7b8f41;
  margin-top: 20px;
  font-size: 1.2em;
}

.media-grid .image-container {
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  padding: 10px;
  display: flex;
}

#stanfordreport {
  width: 200px;
  height: auto;
}

#jojo_garden {
  width: 140px;
  height: auto;
}

#guigu {
  width: 150px;
}

.more-to-come-button {
  text-align: center;
  color: #000;
  cursor: pointer;
  background-color: #0000;
  border: none;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  text-decoration: none;
  display: flex;
}

@media (width <= 1300px) {
  .media-grid .image-container {
    gap: 10px;
    padding: 10px 5px;
  }
}

@media (width <= 768px) {
  .media-grid .image-container {
    padding: 5px;
  }
}

@media (width <= 480px) {
  .media-grid .image-container {
    padding: 2px;
  }
}

@media (width <= 768px) {
  .media-grid img {
    max-width: 100%;
  }
}

.speakers-container {
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  display: flex;
}

.speaker-card {
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  width: 180px;
  margin: 10px;
  transition: transform .3s;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 8px #0003;
}

.speaker-card img {
  object-fit: cover;
  border-bottom: 1px solid #f1f1f1;
  width: 100%;
  height: 200px;
  transition: transform .3s;
}

.speaker-card:hover img {
  transform: scale(1.05);
}

.icon-container {
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  display: flex;
  overflow: hidden;
  transform: translateZ(0);
}

.icon-image {
  object-fit: contain;
  border: none;
  max-width: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
}

.circle-overlay {
  opacity: 0;
  z-index: 10;
  box-sizing: border-box;
  background: #fffc;
  border: 3px solid #aaa;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  text-decoration: none;
  transition: opacity .3s, transform .3s;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.circle-overlay.link-1 {
  top: 10px;
  right: 10px;
}

.circle-overlay.link-2 {
  top: 60px;
  right: 10px;
}

.speaker-card:hover .circle-overlay {
  opacity: 1;
  transform: scale(1.2);
}

.circle-overlay .icon {
  color: #333;
  font-size: 20px;
}

.speaker-card h2 {
  margin: 10px 0;
  font-size: 1.2em;
}

.speaker-card .title {
  color: gray;
  font-size: .9em;
}

.speaker-card p {
  margin: 10px 0;
}

.speaker-card:hover {
  transform: scale(1.05);
}

.speaker-card:hover h2, .speaker-card:hover .title, .speaker-card:hover p {
  transform: translateY(-10px);
}

.modal {
  z-index: 2;
  background-color: #0006;
  width: 100%;
  height: 100%;
  padding-top: 100px;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.modal-content {
  background-color: #fff;
  border: 1px solid #888;
  border-radius: 8px;
  width: 70%;
  max-width: 800px;
  height: 50%;
  margin: auto;
  padding: 20px;
  display: flex;
}

.modal-body {
  align-items: center;
  display: flex;
}

.modal-image {
  flex: none;
  margin-bottom: 15px;
  margin-right: 15px;
  overflow: hidden;
}

.modal-image img {
  object-fit: cover;
  border-radius: 8px;
  width: 150px;
  height: 150px;
  display: block;
}

.modal-text {
  -webkit-user-select: none;
  user-select: none;
  flex: 3;
}

@media (width <= 600px) {
  .speaker-card {
    width: 100px;
  }

  .speaker-card img {
    height: 100px;
  }

  .speaker-card .name {
    font-size: .6em;
  }

  .speaker-card .title {
    color: gray;
    font-size: .3em;
  }

  .speaker-card .organization {
    font-size: .5em;
  }

  .speakers-container {
    gap: 0;
    margin: 35px -10px -10px;
  }

  .modal-content {
    width: 90%;
    height: auto;
    margin: auto;
    padding: 10px;
  }

  .modal-body {
    align-items: flex-start;
    margin-top: 5px;
    padding: 15px;
    display: block;
  }

  .modal-image {
    float: left;
    margin-bottom: 10px;
    margin-right: 15px;
    padding: 0;
  }

  .modal-image img {
    width: 100px;
    height: 100px;
  }

  .modal-text {
    overflow: visible;
  }
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover, .close:focus {
  color: #000;
  cursor: pointer;
  text-decoration: none;
}

#exhibition .first {
  color: #7b8f41;
  margin-top: 20px;
  font-size: 1.2em;
}

#exhibition .second {
  color: #000;
  margin-top: 20px;
  font-size: 1em;
}

#exhibition .third {
  color: #000;
  margin-top: 20px;
  font-size: 1.7em;
  font-weight: bold;
}

#exhibition .fourth {
  color: #7b8f41;
  margin-top: 40px;
  font-size: 1.3em;
  font-weight: bold;
}

#exhibition .fifth {
  color: gray;
  margin-top: 40px;
  font-size: 1.1em;
}

.flex-container {
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 10px;
  max-width: 800px;
  margin: 20px auto 20px 20px;
  padding: 20px;
  box-shadow: 0 4px 8px #0000001a;
}

.dropdown {
  cursor: pointer;
  transition: color .3s;
}

.dropdown:hover {
  color: #7b8f41;
}

details {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 5px;
}

summary {
  outline: none;
  font-weight: bold;
}

details[open] {
  padding-bottom: 10px;
}

.agenda-day {
  margin-bottom: 10px;
  font-size: 1.5rem;
}

.agenda-stack {
  flex-direction: column;
  gap: 5px;
  display: flex;
}

.agenda-item {
  background-color: #f0fff4;
  border-radius: 8px;
  width: 900px;
  padding: 15px;
  box-shadow: 0 2px 4px #0000001a;
}

.agenda-item1 {
  background-color: #f0faff;
  border-radius: 8px;
  width: 900px;
  padding: 15px;
  box-shadow: 0 2px 4px #0000001a;
}

.agenda-stack1 {
  flex-direction: column;
  gap: 5px;
  display: flex;
}

.session-type {
  font-size: 1.6rem;
  font-weight: bold;
}

.agenda-item2 {
  background-color: #f9f0ff;
  border-radius: 8px;
  width: 900px;
  padding: 15px;
  box-shadow: 0 2px 4px #0000001a;
}

.agenda-stack2 {
  flex-direction: column;
  gap: 5px;
  display: flex;
}

.agenda-item3 {
  background-color: #fff0f0;
  border-radius: 8px;
  width: 900px;
  padding: 15px;
  box-shadow: 0 2px 4px #0000001a;
}

.agenda-stack3 {
  flex-direction: column;
  gap: 5px;
  display: flex;
}

.agenda-item4 {
  background-color: ivory;
  border-radius: 8px;
  width: 900px;
  padding: 15px;
  box-shadow: 0 2px 4px #0000001a;
}

.agenda-stack4 {
  flex-direction: column;
  gap: 5px;
  display: flex;
}

.agenda-item5 {
  background-color: #fff7f0;
  border-radius: 8px;
  width: 900px;
  padding: 15px;
  box-shadow: 0 2px 4px #0000001a;
}

@media (width <= 1100px) {
  .agenda-item, .agenda-item1, .agenda-item2, .agenda-item3, .agenda-item4, .agenda-item5 {
    width: auto;
  }
}

.agenda-stack5 {
  flex-direction: column;
  gap: 5px;
  display: flex;
}

.session-time {
  color: #333;
  font-size: 1rem;
}

.session-title {
  color: #000;
  margin-top: 5px;
  font-size: 1.2rem;
  font-weight: bold;
}

.hidden-item {
  display: none;
}

#toggle-button {
  color: #fff;
  cursor: pointer;
  background-color: #7b8f41;
  border: none;
  margin-top: 10px;
  padding: 10px 20px;
}

.session-speaker {
  color: #333;
  margin-top: 5px;
  font-size: 1rem;
}

.modal-content1 {
  background-color: #fff;
  flex-direction: column;
  max-width: 1000px;
  margin: auto;
  padding: 20px;
  display: flex;
}

.modal-body1 {
  gap: 20px;
  display: flex;
}

.map-container1 {
  flex: 1;
}

.text-container1 {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.info-sections1 {
  margin-top: 20px;
}

.dropdown {
  font-weight: bold;
}

details {
  margin-bottom: 10px;
}

.button1 {
  color: #7b8f41;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: #fff3;
  border: 2px solid #7b8f41;
  border-radius: 25px;
  margin: 4px 2px;
  padding: 13px 32px;
  font-size: 16px;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
}

#exhibition button {
  color: #fff;
  cursor: pointer;
  background-color: #7b8f41;
  border: 2px solid #fff;
  margin-top: 10px;
  padding: 7px 16px;
  font-size: 16px;
  font-weight: bold;
  transition: background-color .3s, color .3s;
}

#exhibition .flex-container {
  align-items: center;
  display: flex;
}

#exhibition .text-container {
  flex: 1;
}

#exhibition .image-and-text {
  margin: 30px 30px 10px 20px;
}

#exhibition .image-and-text img {
  max-width: 100%;
  max-height: 400px;
}

#exhibition .image-and-text p {
  color: #5697ad;
  font-size: 1em;
  line-height: 1.5;
}

#about {
  color: #fff;
  background-position: bottom;
  background-size: cover;
  height: 540px;
  line-height: 2;
}

#about h2 {
  color: #fff;
  margin-top: 20px;
  font-size: 1.6em;
}

@media (width <= 1200px) {
  #about {
    height: 700px;
  }

  #about h2 {
    font-size: 1.4em;
  }
}

@media (width <= 992px) {
  #about {
    height: 600px;
  }

  #about h2 {
    font-size: 1.2em;
  }
}

@media (width <= 768px) {
  #about {
    height: 500px;
  }

  #about h2 {
    font-size: 1.1em;
  }
}

@media (width <= 576px) {
  #about {
    height: 400px;
  }

  #about h2 {
    font-size: 1em;
  }
}

.map-container {
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  padding: 20px;
  display: flex;
}

#resource .first {
  color: #7b8f41;
  margin-top: 20px;
  font-size: 1.2em;
}

.button-row {
  flex-wrap: wrap;
  gap: 10px;
  margin-left: 11rem;
  display: flex;
}

.button-row button {
  color: #4caf50;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: #fff3;
  border: 2px solid #4caf50;
  border-radius: 25px;
  margin: 4px 2px;
  padding: 13px 32px;
  font-size: 16px;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
}

.button-row button:hover {
  background-color: #68d168;
  transform: translateY(-5px);
}

@media (width <= 900px) {
  .button-row {
    margin-left: 2.5rem;
  }
}

.event-grid {
  display: flex;
}

.event-partners-container {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  padding: 10px;
  display: flex;
}

#event-partners {
  margin-bottom: 20px;
}

#berkeley, #Berkerly_Event_Service {
  width: 140px;
  height: auto;
}

#ju {
  width: 50px;
  height: auto;
}

#MiLin {
  width: 120px;
  height: auto;
}

#mandlgroup {
  width: 80px;
  height: auto;
}

@media (width <= 1300px) {
  .event-grid .event-partners-container {
    align-items: start;
    gap: 10px;
    padding: 10px 5px;
  }
}

@media (width <= 768px) {
  .event-grid .event-partners-container {
    gap: 5px;
    padding: 5px;
  }

  #berkeley, #Berkerly_Event_Service {
    width: 120px;
    height: auto;
    padding: 0 15px;
  }

  #ju {
    width: 55px;
    height: auto;
    padding: 10px;
  }

  #MiLin {
    width: 80px;
    height: auto;
  }

  #mandlgroup {
    width: 50px;
    height: auto;
  }
}

@media (width <= 480px) {
  .event-grid .event-partners-container {
    padding: 2px;
  }
}

footer {
  background-color: #f9f9f9;
  border-top: 1px solid #e0e0e0;
  padding: 20px;
}

.footer-content {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.footer-left, .footer-right {
  box-sizing: border-box;
  flex: 1;
  padding: 0 15px;
}

.footer-left {
  text-align: left;
  font-family: Open Sans, sans-serif;
}

.footer-right {
  text-align: right;
  font-family: Open Sans, sans-serif;
}

.footer-divider {
  background-color: #ccc;
  flex-shrink: 0;
  width: 1px;
  height: 100px;
  margin: 0 20px;
}

.footer-socials {
  margin-bottom: 5px;
}

.footer-socials a {
  margin: 0 4px;
  text-decoration: none;
}

.footer-socials img {
  width: 20px;
  height: 20px;
}

.footer-left p, .footer-right p {
  margin: 5px 0;
}

.footer-right h2 {
  margin-bottom: 10px;
}

.footer-right p {
  margin-bottom: 5px;
}

@media (width <= 768px) {
  .footer-content {
    padding: 10px;
  }

  .footer-left, .footer-right {
    padding: 0 10px;
  }

  .footer-divider {
    height: 120px;
    margin: 0 10px;
  }

  .footer-socials img {
    width: 16px;
    height: 16px;
  }

  .footer-left p, .footer-right p {
    font-size: 14px;
  }

  .footer-right h2 {
    font-size: 16px;
  }
}
/*# sourceMappingURL=index.50c1627f.css.map */
