* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    overflow-x: hidden;
    background-color: #e6eee6bf;
}
.announcement-banner {
    background-color: red;
    color: white;
    text-align: center;
    padding: 20px;
    font-size: 24px;
    font-weight: bold;
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    top: 0;
    background-color: white;
    z-index: 1000;
}

.header img {
    width: 130px;
    height: auto;
}

.navbar {
    display: none;
    justify-content: center;
    font-family: 'Open Sans', sans-serif;
    user-select: none;
    flex-wrap: wrap;
}

.navbar a {
    display: inline-block;
    color: #787c68;
    padding: 25px 16px;
    text-decoration: none;
    font-size: 17px;
    font-weight: 1000;
    transition: color 0.3s;
}

.navbar a:hover {
    color: #7c9924;
}

.navbar a.active {
    color: #7c9924;
}

.buy-tickets-button {
    background-color: #8b9f4f;
    color: white;
    border-radius: 40px;
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    width: 150px;
    height: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 17px;
    margin-left: 10px;
    font-size: 14px; /* Ensure the font size is consistent */
    font-weight: 800; /* Ensure the font weight is consistent */
}


.buy-tickets-button:hover {
    background-color: #7B8F41;
    color: white;
}

.hamburger {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: 10px;
}

.hamburger span {
    height: 3px;
    width: 25px;
    background: #8b9f4f;
    margin: 4px 0;
    transition: 0.4s;
}

/* Responsive styles */
@media (min-width: 768px) {
    .navbar {
        display: flex; /* Show the navbar on larger screens */
    }

    .hamburger {
        display: none; /* Hide the hamburger menu on larger screens */
    }
}

@media (max-width: 768px) {
    .navbar {
        display: none;
        flex-direction: column;
        align-items: center;
        width: 100%;
        background-color: white;
        position: absolute;
        top: 60px;
        left: 0;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .navbar a {
        padding: 15px 10px;
        font-size: 14px;
        width: 100%;
        text-align: center;
    }

    .navbar .buy-tickets-button {
        margin-top: 10px;
        width: 80%;
    }

    .hamburger {
        display: flex;
    }

    .navbar.active {
        display: flex;
    }
}

@media (max-width: 480px) {
    .header {
        padding: 0 10px;
    }

    .header img {
        margin-bottom: 10px;
    }

    .navbar a {
        font-size: 12px;
        padding: 10px;
    }

    .navbar .buy-tickets-button {
        font-size: 14px;
        padding: 10px;
    }
}

/* home section */
@keyframes myanim {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.animateme {
    animation: myanim 3s;
}

#backToTopBtn {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 99;
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

#backToTopBtn:hover {
    opacity: 0.8;
}

#home {
    position: relative; /* Ensure the container is positioned relatively for absolute positioning of the video */
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding-top: 130px; /* Ensure content starts below the fixed header */
    margin-top: 80px; /* Adjust margin to create space between header and section */
    padding-left: 10%; /* Adjust this value to control the left margin */
}

#background-video, #fallback-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the video covers the entire container */
    z-index: -1; /* Place the video behind other content */
    pointer-events: none; /* Ensure the video doesn't interfere with interactions */
}

.horizontal-space {
    margin-left: 20px; /* Adjust margin as needed */
}
#home h3 {
    margin: 0;
    font-size: 2.125em;
    color: #b5d07b;
}

#home h1 {
    margin: 0;
    font-size: 4.375em;
    color: white;
}


#home p {
    margin-top: 10px;
    font-size: 1em;
    color: white;
}

#home button {
    margin-top: 10px;
    font-weight: bold;
    font-size: 16px;
    background-color: white;
    color: #7B8F41;
    border: 2px solid #7B8F41;
    padding: 7px 16px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

#home button:hover {
    background-color: #7B8F41;
    color: white;
}
#countdown {
    font-size: 20px;
    margin-top: 10px;
    color: white; /* Text color */
    background-color: #7B8F41; /* Background color */
    padding: 5px; /* Space inside the block */
    border-radius: 5px; /* Optional: rounded corners */
    display: inline-block; /* Ensures the block only takes up as much space as the content */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: adds a subtle shadow for depth */
    margin-left: 20px;
}

/* Style for the section */
#sponsors,
#media,
#speakers,
#exhibition,
#agenda,
#about,
#location,
#resource,
#event_partners
{
    position: relative;
    padding: 40px 0; /* Adjust padding as needed */
    overflow: hidden; /* Hide overflow to contain background image */
}

/* Style for the content within the section */
#sponsors .content,
#media .content,
#speakers .content,
#exhibition .content,
#agenda .content,
#about .content,
#location .content,
#resource .content,
#event_partners .content {
    position: relative; /* Ensure content is positioned relative to the section */
    z-index: 1; /* Ensure content is above the background image */
    padding: 20px;
    margin-left: 10rem;
}

/* Style for the h1 heading */
#sponsors h1,
#media h1,
#speakers h1,
#exhibition h1,
#agenda h1,
#about h1,
#location h1,
#resource h1,
#event_partners h1 {
    font-size: 2.5em; /* Example font size */
    /* Add more styles as needed */
}

.heading-with-icon {
    display: inline-block;
    position: relative;
    font-size: 3em;
    font-weight: bold;
    margin: 0;
}

/*
.heading-with-icon::before {
    content: url("../image/leaves1.png");
    position: absolute;
    top: 50%;
    left: -65px;
    transform: translateY(-50%) scale(0.5);
    width: 1px;
    height: -1px;
    z-index: -1;
}
*/


.heading-with-icon::before {
    content: url("../image/solar1.png");
    position: absolute;
    top: 50%;
    left: -110px;
    transform: translateY(-52%) scale(0.29);
    width: 1px;
    height: -1px;
    z-index: -1;
}


/* Media query for smaller screens */
@media (max-width: 900px) {
    #sponsors .content,
    #media .content,
    #speakers .content,
    #exhibition .content,
    #agenda .content,
    #about .content,
    #location .content,
    #resource .content,
    #event_partners .content {
        margin-left: 0rem; /* Adjust margin-left for smaller screens */
    }

    .heading-button-container {
        margin-left: 3rem;
    }

    .heading-with-icon::before {
        left: -120px; /* Adjust icon position for smaller screens */
    }

}

.sponsor-container {
    display: grid;
    grid-template-columns: auto 1fr; /* Two columns: one for categories, one for logos */
    gap: 10px; /* Ensure consistent spacing between items */
    align-items: start; /* Align items to the top */
}

.sponsor-item {
    display: contents; /* Each item acts as a grid cell */
}

.sponsor-box {
    width: 100%; /* Adjust based on your layout */
    height: auto; /* Maintain aspect ratio */
    display: block; /* Remove default inline spacing */
}

.sponsor-box p {
    text-align: center; /* Center text */
    margin: 0; /* Remove default margin */
}

.sponsor-text {
    font-size: 27px;
    color: white;
    font-weight: 1000;
}

.special-sponsor {
    grid-column: 1; /* Place in the first column */
    width: 180px; /* Set the width of each box */
    height: 60px; /* Set the height of each box */
    display: flex;
    margin-top: 1rem;
    justify-content: center;
    align-items: center;
    user-select: none; /* Makes the text non-selectable */
}

.special-sponsor-text {
    font-size: 24px;
    font-weight: 1000;
    margin-left: auto;
    font-family: 'Pacifico', cursive;
}

/* Unified styles for all logo containers */
.logo-container,
.logo-container1,
.logo-container2,
.logo-container3,
.logo-container4 {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; /* Start logos at the beginning */
    align-items: center;
    padding: 10px; /* Adjust padding as needed */
    gap: 20px; /* Adjust the spacing between logos */
}

/* Styles for individual logos */
.sponsor-logo{
    max-width: 100%; /* Ensure the logo fits within the container */
    max-height: 100%; /* Ensure the logo fits within the container */
    width: auto; /* Maintain aspect ratio */
    height: auto; /* Maintain aspect ratio */
    object-fit: contain; /* Ensure the image maintains its aspect ratio */
}

/* Responsive adjustments */
@media (max-width: 1300px) {
    .logo-container,
    .logo-container1,
    .logo-container2,
    .logo-container3,
    .logo-container4 {
        padding: 0px; /* Adjust padding as needed */
        gap: 20px; /* Adjust the spacing between logos */
    }
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .logo-container,
    .logo-container1,
    .logo-container2,
    .logo-container3,
    .logo-container4 {
        padding: 0px; /* Adjust padding as needed */
        gap: 10px; /* Adjust the spacing between logos */
    }
}

@media (max-width: 480px) {
    .logo-container,
    .logo-container1,
    .logo-container2,
    .logo-container3,
    .logo-container4 {
        padding: 0px; /* Adjust padding as needed */
        gap: 5px; /* Adjust the spacing between logos */
    }
}

#sponsors{
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
}

#sponsors .heading-with-icon {
    flex-grow: 1;
}

#sponsors .heading-button-container {
    display: flex;
    justify-content: flex-start; /* Distribute space between heading and button */
    align-items: center; /* Align items vertically in the center */
    gap: 20px; /* Initial gap between heading and button */
}

/* Style for the link wrapping the button */
.heading-button-container a {
    text-decoration: none; /* Remove underline from the link */
}

#sponsors .sponsor-button {
    display: flex;
    align-items: center; /* Vertically center the content */
    justify-content: center; /* Horizontally center the content if needed */
    font-family: 'Open Sans', sans-serif;
    padding: 10px 20px;
    font-weight: bold;
    font-size: 20px;
    background-color: white;
    color: #7B8F41;
    border: 2px solid #7B8F41;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, padding-right 0.3s;
    position: relative;
    margin-right: 7rem; /* Add some initial margin */
}

#sponsors .sponsor-button:hover {
    color: white;
    background-color: #7B8F41;
    padding-right: 40px;
}

#sponsors .sponsor-button:hover::after {
    content: '→';
    position: absolute;
    right: 10px;
    transition: right 0.3s ease;
    font-size: 23px;
    color: white;
}

.sponsor-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 170px; /* Set fixed width for the container */
    height: 60px; /* Set fixed height for the container */
    padding: 10px; /* Adjust padding as needed */
    text-decoration: none; /* Remove underline from links */
    transition: background-color 0.3s ease; /* Smooth transition for background color */
    border-radius: 2rem; /* Add some rounding to the background */
    background-color: transparent; /* Initial background color */
}

.sponsor-link:hover, .sponsor-link:active {
    background-color: rgb(85, 201, 85); /* Change background color on hover and active */
}

.sponsor-link:active {
    transition: none; /* Disable transition for active state to ensure immediate feedback */
}

/* Media query for smaller screens */
@media (max-width: 1000px) {
    #sponsors .sponsor-button {
        padding: 5px 10px;
        font-size: 10px;
        right: 3rem;
        white-space: normal; /* Allow text to wrap */
    }

    #sponsors .sponsor-button:hover::after {
        content: '→';
    }
}

.sponsor-images {
    display: flex;
    justify-content: space-between; /* Adjust spacing between left and right images */
}

.left-image,
.right-images {
    flex: 1;
}
.left-image {
    margin-top: 200px; /* Adjust the margin-top to move the left image down */
}

.right-images {
    display: flex;
    flex-direction: column;
}

.small-image {
    max-width: 450px; /* Set maximum width for the images */
    max-height: 450px; /* Set maximum height for the images */
    width: auto; /* Maintain aspect ratio */
    height: auto; /* Maintain aspect ratio */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .sponsor-box {
        width: 100px; /* Further reduce width of sponsor boxes */
        height: 34px; /* Further reduce height of sponsor boxes */
        font-size: 18px; /* Further reduce font size of sponsor text */
    }

    .sponsor-images {
        flex-wrap: wrap;
        margin-top: 2rem; /* Adjust top margin for sponsor images */
    }

    #sponsors .sponsor-button {
        margin-right: 30px;
    }

    .logo-container,
    .logo-container1,
    .logo-container2,
    .logo-container3,
    .logo-container4 {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    .special-sponsor {
        width: 130px; /* Set the width of each box */
        height: 50px; /* Set the height of each box */
        justify-content: center;
        user-select: none; /* Makes the text non-selectable */
    }

    .sponsor-link {
        width: 105px;
        height: 40px; /* Set fixed height for the container */
    }
}


#media .first {
    margin-top: 20px;
    font-size: 1.2em;
    color: #7B8F41;
}

.media-grid .image-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;
    padding: 10px;
    gap: 20px; /* Adjust spacing between images and button as needed */
}

#stanfordreport {
    width: 200px; /* Make image fill the container */
    height: auto; /* Maintain aspect ratio */
}

#jojo_garden {
    width: 140px;
    height: auto;
}

#guigu {
    width: 150px;
}

.more-to-come-button {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    color: black; /* Text color */
    font-size: 16px;
    cursor: pointer;
    text-decoration: none; /* Ensure no underline */
}

/* Responsive adjustments */
@media (max-width: 1300px) {
    .media-grid .image-container {
        padding: 10px 5px;
        gap: 10px;
    }

}

/* Responsive adjustments */
@media (max-width: 768px) {
    .media-grid .image-container {
        padding: 5px;
    }
}

@media (max-width: 480px) {
    .media-grid .image-container {
        padding: 2px;
    }

}

@media (max-width: 768px) {
    .media-grid img {
        max-width: 100%; /* Make images responsive on smaller screens */
    }
}

/* Container for the speaker cards */
.speakers-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px; /* Space between cards */
}

/* Individual speaker cards */
.speaker-card {
    position: relative; /* Ensure absolute positioning of the circle-overlay works */
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    width: 180px;
    text-align: center;
    margin: 10px;
    border-radius: 10px; /* Rounded corners */
    overflow: hidden; /* Ensure content stays within rounded corners */
    transition: transform 0.3s; /* Smooth hover effect */
}

.speaker-card img {
    width: 100%;
    height: 200px; /* Fixed height for all images */
    object-fit: cover; /* Ensure the image covers the entire area */
    border-bottom: 1px solid #f1f1f1; /* Border under image */
    transition: transform 0.3s; /* Smooth image zoom effect */
}

.speaker-card:hover img {
    transform: scale(1.05); /* Slight zoom effect on hover */
}

.icon-container {
    width: 24px;  /* Adjust size as needed */
    height: 24px; /* Adjust size as needed */
    display: flex; /* Center content */
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    overflow: hidden; /* Prevent overflow */
    transform: translateZ(0); /* Force hardware acceleration */
}

.icon-image {
    max-width: 100%; /* Ensure image fits within the container */
    max-height: 100%; /* Maintain aspect ratio */
    object-fit: contain; /* Prevent distortion */
    border: none; /* Ensure there's no border around the image */
    margin: 0; /* Remove default margins */
    padding: 0;
}

/* Circle overlay styles */
.circle-overlay {
    position: absolute;
    width: 40px; /* Size of the small circle */
    height: 40px; /* Size of the small circle */
    background: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
    border-radius: 50%;
    border: 3px solid #aaa; /* Border around the circle */
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0; /* Hidden by default */
    transition: opacity 0.3s, transform 0.3s; /* Smooth transitions */
    text-decoration: none; /* Remove underline from link */
    z-index: 10; /* Ensure it appears above other content */
    overflow: hidden; /* Prevent overflow that can cause lines */
    box-sizing: border-box;
}

/* Position for the first circle */
.circle-overlay.link-1 {
    top: 10px; /* Position at the top */
    right: 10px; /* Position at the right */
}

/* Position for the second circle */
.circle-overlay.link-2 {
    top: 60px; /* Position below the first circle */
    right: 10px; /* Align with the right of the card */
}

/* Show circles on hover */
.speaker-card:hover .circle-overlay {
    opacity: 1; /* Show the circles */
    transform: scale(1.2); /* Slightly enlarge the circle */
}

.circle-overlay .icon {
    font-size: 20px; /* Adjust icon size as needed */
    color: #333; /* Icon color */
}

.speaker-card h2 {
    font-size: 1.2em;
    margin: 10px 0;
}

.speaker-card .title {
    color: grey;
    font-size: 0.9em;
}

.speaker-card p {
    margin: 10px 0;
}

/* Hover effect */
.speaker-card:hover {
    transform: scale(1.05); /* Slightly enlarge the card on hover */
}

.speaker-card:hover h2,
.speaker-card:hover .title,
.speaker-card:hover p {
    transform: translateY(-10px); /* Move text upwards on hover */
}

.modal {
    display: none;
    position: fixed;
    z-index: 2;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
}

.modal-content {
    background-color: #ffffff; /* Light green background */
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 70%;
    height: 50%;
    max-width: 800px;
    display: flex;
    border-radius: 8px;
}

.modal-body {
    display: flex;
    align-items: center;
}

.modal-image {
    flex: 0 0 auto; /* Image should not grow or shrink */
    margin-right: 15px; /* Space between the image and the text */
    margin-bottom: 15px; /* Space below the image */
    overflow: hidden; /* Ensure proper wrapping */
}

.modal-image img {
    width: 150px; /* Fixed width */
    height: 150px; /* Fixed height */
    object-fit: cover; /* Ensure image covers the container */
    border-radius: 8px;
    display: block;
}

.modal-text {
    flex: 3;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera, and Edge */
}

/* Media query for mobile devices */
@media (max-width: 600px) {
    .speaker-card {
        width: 100px; /* Smaller width for mobile */
    }

    .speaker-card img {
        height: 100px; /* Smaller height for mobile */
    }

    .speaker-card .name {
        font-size: 0.6em;
    }

    .speaker-card .title {
        color: grey;
        font-size: 0.3em;
    }

    .speaker-card .organization {
        font-size: 0.5em;
    }

    .speakers-container {
        gap: 0px; /* Space between cards */
        margin: -10px; /* Adjust the negative margin to pull the items closer */
        margin-top: 35px;
    }

    .modal-content {
        margin: auto;
        padding: 10px;
        width: 90%;
        height: auto;
    }

    .modal-body {
        align-items: flex-start; /* Align items to the top */
        padding: 15px;
        margin-top: 5px;
        display: block; /* Stack image and text vertically on mobile */
    }

    .modal-image {
        float: left; /* Float the image to the left for text wrapping */
        margin-right: 15px; /* Space between the image and the text */
        margin-bottom: 10px; /* Space below the image */
        padding: 0; /* Remove padding */
    }

    .modal-image img {
        width: 100px; /* Smaller width for mobile */
        height: 100px; /* Smaller height for mobile */
    }

    .modal-text {
        overflow: visible; /* Allow text to flow around the floated image */
    }

}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

#exhibition .first{
    margin-top: 20px;
    font-size: 1.2em;
    color: #7B8F41;
}
#exhibition .second {
    margin-top: 20px;
    font-size: 1em;
    color: black;
}
#exhibition .third {
    margin-top: 20px;
    font-size: 1.7em;
    font-weight: bold;
    color: black;
}
#exhibition .fourth {
    margin-top: 40px;
    font-size: 1.3em;
    font-weight: bold;
    color: #7B8F41;
}

#exhibition .fifth {
    margin-top: 40px;
    font-size: 1.1em;
    color: grey;
}
.flex-container {
    background-color: #fff; /* White background */
    padding: 20px;          /* Padding inside the box */
    margin: 20px auto;      /* Margin around the box */
    border-radius: 10px;    /* Rounded corners (optional) */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth (optional) */
    max-width: 800px;       /* Maximum width for the container */
    box-sizing: border-box; /* Include padding in the element's total width and height */
    margin-left: 20px;
}

.dropdown {
    cursor: pointer; /* Pointer cursor to indicate clickability */
    transition: color 0.3s ease; /* Smooth transition for color change */
}

/* Change color on hover */
.dropdown:hover {
    color: #7B8F41; /* Change text color to green on hover */
}

/* Additional styling for details and summary */
details {
    margin-bottom: 10px; /* Space between sections */
    border: 1px solid #ccc; /* Optional border for separation */
    border-radius: 5px; /* Rounded corners */
    padding: 5px; /* Padding for better spacing */
}

summary {
    font-weight: bold; /* Bold text for summary */
    outline: none; /* Remove default outline on focus */
}

details[open] {
    padding-bottom: 10px; /* Extra padding when details are open */
}

.agenda-day {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.agenda-stack {
    display: flex;
    flex-direction: column;
    gap: 5px;
}


.agenda-item {
    background-color: #f0fff4;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 900px;
}

.agenda-item1 {
    background-color: #f0faff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 900px;
}

.agenda-stack1 {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.session-type {
    font-size: 1.6rem;
    font-weight: bold;
}


.agenda-item2 {
    background-color: #f9f0ff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 900px;
}
.agenda-stack2 {
    display: flex;
    flex-direction: column;
    gap: 5px;
}


.agenda-item3 {
    background-color: #fff0f0;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 900px;
}
.agenda-stack3 {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.agenda-item4 {
    background-color: #fffff0;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 900px;
}
.agenda-stack4 {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.agenda-item5 {
    background-color: #fff7f0;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 900px;
}

@media (max-width: 1100px) {
    .agenda-item,
    .agenda-item1,
    .agenda-item2,
    .agenda-item3,
    .agenda-item4,
    .agenda-item5 {
        width: auto;
    }
}

.agenda-stack5 {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.session-time {
    font-size: 1rem;
    color: #333;
}

.session-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 5px;
    color: black;
}
.hidden-item {
    display: none;
}

#toggle-button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #7B8F41;
    color: white;
    border: none;
    cursor: pointer;
}
.session-speaker {
    font-size: 1rem;
    color: #333;
    margin-top: 5px;
}
.modal-content1 {
    display: flex;
    flex-direction: column;
    background-color: white; /* Add this line for white background */
    padding: 20px;
    max-width: 1000px; /* Smaller overall width */
    margin: auto;
}
.modal-body1 {
    display: flex;
    gap: 20px;
}
.map-container1 {
    flex: 1;
}
.text-container1 {
    flex: 1;
    display: flex;
    flex-direction: column;
}
.info-sections1 {
    margin-top: 20px;
}
.dropdown {
    font-weight: bold;
}
details {
    margin-bottom: 10px;
}
.button1 {
    background-color: rgba(255, 255, 255, 0.2); /* Transparent middle */
    border: 2px solid #7B8F41; /* Green border */
    color: #7B8F41; /* Green text */
    padding: 13px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 25px; /* More rounded corners */
    user-select: none;
    transition: all 0.3s ease; /* Smooth transition for all properties */
}
#exhibition button {
    margin-top: 10px;
    font-weight: bold;
    font-size: 16px;
    background-color: #7B8F41;
    color: white;
    border: 2px solid white;
    padding: 7px 16px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}


#exhibition .flex-container {
    display: flex;
    align-items: center; /* Vertical alignment */
}

#exhibition .text-container {
    flex: 1; /* Take remaining space */
}

#exhibition .image-and-text {
    margin-left: 20px; /* Adjust spacing between text and image */
    margin-top: 30px; /* Add margin to the top */
    margin-bottom: 10px;
    margin-right: 30px;
}

#exhibition .image-and-text img {
    max-width: 100%; /* Ensure image does not exceed container width */
    max-height: 400px;
}


#exhibition .image-and-text p {
    font-size: 1em;
    line-height: 1.5;
    color: rgb(86, 151, 173); /* Adjust text color as needed */
}

#about {
    background-size: cover;
    color: white;
    line-height: 2;
    background-position: center bottom; /* Adjusted to show more of the top part */
    height: 540px;
}

#about h2{
    margin-top: 20px;
    color: white;
    font-size: 1.6em;

}
@media (max-width: 1200px) {
    #about {
        height: 700px;
    }

    #about h2 {
        font-size: 1.4em;
    }
}

@media (max-width: 992px) {
    #about {
        height: 600px;
    }

    #about h2 {
        font-size: 1.2em;
    }
}

@media (max-width: 768px) {
    #about {
        height: 500px;
    }

    #about h2 {
        font-size: 1.1em;
    }
}

@media (max-width: 576px) {
    #about {
        height: 400px;
    }

    #about h2 {
        font-size: 1em;
    }
}

.map-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px; /* Adjust padding as needed */
    margin-top: 30px;
}

#resource .first{
    margin-top: 20px;
    font-size: 1.2em;
    color: #7B8F41;
}

.button-row {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Adds space between the buttons */
    margin-left: 11rem;
}

.button-row button {
    background-color: rgba(255, 255, 255, 0.2); /* Transparent middle */
    border: 2px solid #4CAF50; /* Green border */
    color: #4CAF50; /* Green text */
    padding: 13px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 25px; /* More rounded corners */
    user-select: none;
    transition: all 0.3s ease; /* Smooth transition for all properties */
}

.button-row button:hover {
    background-color: rgb(104, 209, 104); /* Light green background on hover */
    transform: translateY(-5px); /* Moves the button up */
}

/* Media query for smaller screens */
@media (max-width: 900px) {
    .button-row {
        margin-left: 2.5rem;
    }
}

.event-grid {
    display: flex;
}

.event-partners-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; /* Start logos at the beginning */
    align-items: center;
    padding: 10px; /* Adjust padding as needed */
    gap: 20px; /* Adjust the spacing between logos */
    margin-top: 20px;
}

#event-partners {
    margin-bottom: 20px;
}

#berkeley {
    width: 140px; /* Make image fill the container */
    height: auto; /* Maintain aspect ratio */
}

#Berkerly_Event_Service {
    width: 140px; /* Make image fill the container */
    height: auto; /* Maintain aspect ratio */
}

#ju {
    width: 50px; /* Make image fill the container */
    height: auto; /* Maintain aspect ratio */
}

#MiLin {
    width: 120px; /* Make image fill the container */
    height: auto; /* Maintain aspect ratio */
}

#mandlgroup {
    width: 80px; /* Make image fill the container */
    height: auto; /* Maintain aspect ratio */
}

#mandlgroup {
    width: 80px; /* Make image fill the container */
    height: auto; /* Maintain aspect ratio */
}

/* Responsive adjustments */
@media (max-width: 1300px) {
    .event-grid .event-partners-container {
        padding: 10px 5px;
        gap: 10px;
        align-items: start; /* Align items to the top */
    }
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .event-grid .event-partners-container {
        padding: 5px;
        gap: 5px;
    }

    #berkeley {
        width: 120px; /* Make image fill the container */
        height: auto; /* Maintain aspect ratio */
        padding: 0px 15px;
    }

    #Berkerly_Event_Service {
        width: 120px; /* Make image fill the container */
        height: auto; /* Maintain aspect ratio */
        padding: 0px 15px;
    }

    #ju {
        width: 55px; /* Make image fill the container */
        height: auto; /* Maintain aspect ratio */
        padding: 10px;
    }

    #MiLin {
        width: 80px; /* Make image fill the container */
        height: auto; /* Maintain aspect ratio */
    }

    #mandlgroup {
        width: 50px; /* Make image fill the container */
        height: auto; /* Maintain aspect ratio */
    }
}

@media (max-width: 480px) {
    .event-grid .event-partners-container {
        padding: 2px;
    }
}

/* Footer styling */
footer {
    background-color: #f9f9f9;
    padding: 20px;
    border-top: 1px solid #e0e0e0;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-left,
.footer-right {
    flex: 1;
    padding: 0 15px; /* Reduced padding for smaller screens */
    box-sizing: border-box;
}

.footer-left {
    text-align: left;
    font-family: 'Open Sans', sans-serif;
}

.footer-right {
    text-align: right;
    font-family: 'Open Sans', sans-serif;
}

.footer-divider {
    flex-shrink: 0;
    width: 1px;
    height: 100px;
    background-color: #ccc;
    margin: 0 20px;
}

.footer-socials {
    margin-bottom: 5px;
}

.footer-socials a {
    margin: 0 4px;
    text-decoration: none;
}

.footer-socials img {
    width: 20px;
    height: 20px;
}

.footer-left p,
.footer-right p {
    margin: 5px 0;
}

.footer-right h2 {
    margin-bottom: 10px;
}

.footer-right p {
    margin-bottom: 5px;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .footer-content {
        padding: 10px; /* Reduced padding for smaller screens */
    }
    .footer-left, .footer-right {
        padding: 0 10px; /* Reduced padding for smaller screens */
    }
    .footer-divider {
        height: 120px; /* Further reduced height for smaller screens */
        margin: 0 10px; /* Further reduced margin for smaller screens */
    }
    .footer-socials img {
        width: 16px; /* Further reduced size for smaller screens */
        height: 16px;
    }
    .footer-left p, .footer-right p {
        font-size: 14px; /* Reduced font size for smaller screens */
    }
    .footer-right h2 {
        font-size: 16px; /* Reduced font size for smaller screens */
    }
}
